// @import '@sweetalert2/theme-material-ui/material-ui.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PurchaseCmp{
  direction: rtl;
  .purchaseTable{
    
  direction: ltr;
    width: 100%;
    input[type=text], input[type=number]{
      width:100%;
      direction: rtl;
      padding: 2px;
    }
    .td1 {
      width: 18%;
      input{
        text-align: center;
        color: gray;
      }
    }
    .td2 {
      width: 20%;
    }
    .td3 {
      width: 17%;
    }
    .td4 {
      width: 37%;
    }
    .td5 {
      width: 8%;
    }
  }
  .totalTable{
    input{
      text-align: center;
      color: gray;
    }
  }
  .addRow{
    background-color: white;
    border: 0px solid white;
    color: rgb(91, 182, 0);
    font-size: 20px;
    float: right;
    button{
      float: right;
    }
  }
  
  .minusRow{
    color: red;
  }
}

.collapsableTabl{
  direction: rtl;
  .table{
    direction: rtl;
  }
}
.simpleTable{
  direction: rtl;
}

.sumAmout{ 
  color: #01a925;
  font-weight: bold;
  font-size: 300%;
  padding: 0px;
  margin: 0px;
  direction: rtl;
}

.roznamcha{
  table{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    direction: rtl;
    padding: 10px;
    width: 100%;
    tr{
      background-color: rgb(251 251 251);
    } 
    th:first-child{
      text-align: right;
      width: 80%;
      width: 20%;
      padding: 1% 0px;
      font-size: 110%;
    }
    th:nth-child(2){
      text-align: left;
      width: 20%;
      padding: 1% 0px;
      font-size: 11 0%;
    }
    td:first-child{
      text-align: right;
      width: 80%;
      padding: 1% 0px;
      font-size: 110%;
    }
    td:nth-child(2){
      text-align: left;
      width: 20%;
      padding: 1% 0px;
      font-size: 110%;
    }
    tfoot{
      td{
        float: right;
        width: 100% !important;
        text-align: right !important;
      }
    }
    .lastLineSum{
      background-color: rgb(211, 211, 211) !important;
    }
    .bottomLine{
      background-color: aliceblue !important;
    }
  }

  .deleteIconHolder div{
    float: right;
    svg{
      width: 20px;
    }
  }
  .deleteIcon{
    color: rgb(224, 17, 17);
  }
}
.errorMsg{
  color: rgb(224, 17, 17);
}
.loggedOut{
  float: right;
  width: 48%;
}
.uploadData{
  float: left;
  width: 48%;
}

.currencyActivate{
  color: black
}
.uploadDate{
  color: black
}
.selectedButton{
  background: #f1f1f1 !important;
  margin: 5px 0px 0px 0px !important;
  color: #e7003e !important;
}

@media print {
.printHide, .printHideTabs{
  display: none;
}
}